import Vue from "vue";
import Vuex from "vuex";
// import Common from "@/lib/common";
/*
import SecureLS from "secure-ls";
import createPersistedState from "vuex-persistedstate";
*/
/*
var lib = new SecureLS({
  encodingType: "aes",
  isCompression: false,
  encryptionSecret: "nestbutler-secret",
});
*/

Vue.use(Vuex);

/*
const sessionStoragePlugin = createPersistedState({
  key: "lele",
  storage: {
    getItem: (key) => ls.get(key),
    setItem: (key, value) => ls.set(key, value),
    removeItem: (key) => ls.remove(key),
    // getItem: (key) => window.sessionStorage.getItem(key),
    // setItem: (key, value) => window.sessionStorage.setItem(key, value),
    // removeItem: (key) => window.sessionStorage.removeItem(key),
  },
});
*/

// const localStorePlugin = (store) => {
//   // 當 store 初始化後調用
//   store.subscribe((mutation, state) => {
//     // 每次 mutation 之後調用
//     // mutation 的格式為 { type, payload }
//     console.log("[STORE] mutation :", mutation);
//     console.log("[STORE] state :", state);
//   });
// };

export default new Vuex.Store({
  // plugins: [sessionStoragePlugin],
  state: {
    drawerStatus: false,
    userData: null,
    selectCommunity: null,
    themeColor: "#33a6b8",
    themeColor_light: "#81c7d4",
    themeColor_light2: "#9dcfd9",

    windowWidth: 1024,
    windowHeight: 768,
    isMobile: false,
    // isAdmin: false,
    jwttoken: null,
    permissions: [],
  },
  getters: {
    getThemeColor(state) {
      return state.themeColor;
    },
    getThemeColorLight(state) {
      return state.themeColor_light;
    },
    getThemeColorLight2(state) {
      return state.themeColor_light2;
    },
    getWindowWidth(state) {
      return state.windowWidth;
    },
    getWindowHeight(state) {
      return state.windowHeight;
    },
    getToken(state) {
      // return ls.get("jt");
      return state.jwttoken;
    },
    getSelectCommunity(state) {
      return state.selectCommunity;
    },
    // getCommunityName(state) {
    //   return state.communityName;
    // },
    getUser(state) {
      return state.userData;
    },
    isMobile(state) {
      return state.isMobile;
    },
    isDrawerShow(state) {
      return state.drawerStatus;
    },
    // hasModule(state, moduleName) {
    //   if (!moduleName) return true;

    //   for (let mi = 0; mi < state.selectCommunity.Module.length; mi++) {
    //     if (state.selectCommunity.Module[mi].Name == moduleName) {
    //       return true;
    //     }
    //   }
    //   return false;
    // },
  },
  mutations: {
    login(state, token) {
      state.jwttoken = token;
      window.sessionStorage.setItem("jwttoken", token);
    },
    setUser(state, user) {
      state.userData = user;
      window.sessionStorage.setItem("userData", JSON.stringify(user));
    },
    setDrawerShow(state, isShow) {
      state.drawerStatus = isShow;
    },
    setWindowResolution(state, { width, height }) {
      state.windowWidth = width;
      state.windowHeight = height;
      state.isMobile = width < 769;
    },
    setSelectCommunity(state, community) {
      state.selectCommunity = community;
      window.sessionStorage.setItem("selectCommunity", JSON.stringify(community));
    },
    logout(state) {
      state.jwttoken = null;
      state.menuStatus = null;
      state.userData = null;
      window.sessionStorage.removeItem("jwttoken");
      window.sessionStorage.removeItem("selectCommunity");
      window.sessionStorage.removeItem("userData");
    },
  },
  actions: {},
  modules: {},
});

// Subscribe to store updates
// console.log(Vuex);
// Vuex.subscribe((mutation, state) => {
//   // Store the state object as a JSON string
//   // localStorage.setItem('store', JSON.stringify(state));
//   console.log("[STORE] mutation :", mutation);
//   console.log("[STORE] state :", state);
// });
