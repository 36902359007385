<template>
  <div>
    <router-view />
    <v-snackbar v-model="showNotify" :color="notifyStatus" :timeout="notifyTimeout" top>
      <div>{{ notifyText }}</div>
    </v-snackbar>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "App",
  data() {
    return {
      showNotify: false,
      notifyStatus: "",
      notifyText: "",
      notifyTimeout: 1000,
    };
  },

  mounted() {
    document.title = this.$vuetify.lang.t("$vuetify.appName") + " - " + this.$vuetify.lang.t("$vuetify.slogan");

    Vue.prototype.$showSuccessNotify = this.showSuccessNotify;
    Vue.prototype.$showErrorNotify = this.showErrorNotify;
    // this.$vuetify.theme.dark = true;
  },
  methods: {
    showSuccessNotify(text) {
      this.notifyStatus = "#43A047";
      this.notifyText = text;
      this.notifyTimeout = 4000;
      this.showNotify = true;
    },
    showErrorNotify(text) {
      this.notifyStatus = "#B71C1C";
      this.notifyText = text;
      this.notifyTimeout = 4000;
      this.showNotify = true;
    },
  },
};
</script>

<style>
div {
  font-family: Circle !important;
  font-size: 17px;
}

.theme {
  background: linear-gradient(147deg, #42a9b9 0%, #33a6b8 67%);
}
</style>

<!-- <style>
@import "./assets/font/font.css";
div {
  font-family: Circle;
}
</style> -->
